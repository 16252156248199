import React from 'react';
import {graphql, Link} from 'gatsby';
import styled from 'styled-components';
import {GatsbyImage} from "gatsby-plugin-image";
import HeaderWithLine from '../components/HeaderWithLine';
import SEO from '../components/SEO';

const StyledWrapper = styled.div`
  margin-top: 110px;
  background-color: #f5f5f5;
`;

const StyledGallery = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding-bottom: 4rem;
`;

const GalleryItems = styled.div`
  display: grid;
  width: 100rem;
  grid-gap: 2rem;
  align-items: start;
  margin: 50px;

  ${({theme}) => theme.mq.tablet} {
    margin: 50px 100px;
  }

  ${({theme}) => theme.mq.desktop} {
    margin: 50px 100px 100px 250px;
    grid-template-columns: repeat(2, minmax(20rem, 50rem));
  }
`;

const GalleryItem = styled(Link)`
  position: relative;
  background-color: #fff;
  border-radius: 0.4rem;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  color: ${({theme}) => theme.black};
  text-align: center;
  transition-duration: 0.5s;

  &:hover {
    transition-duration: 0.5s;
    transform: translateY(-0.5%);
    box-shadow: 0 4rem 8rem rgba(0, 0, 0, 0.2);
  }
`;

const StyledImg = styled(GatsbyImage)`
  display: block;
  width: 100%;
  height: 25rem;
  object-fit: cover;
`;

const Content = styled.div`
  padding: 1.5rem;
  text-align: center;
`;

const StyledTextWithLineOffers = styled(HeaderWithLine)`
  padding-top: 4rem;
`;

export default function GalleryPage({data}) {
    return (
        <>
            <SEO
                title="Galeria - Realizacje w Poznaniu, Komornikach, Luboniu"
                description="Obejrzyj galerie projektów i urządzonych ogrodów, pielęgnacje terenów zieleni oraz system nawadniające. Zobacz realizacje w Poznaniu, Komornikach, Luboniu."
            />
            <StyledWrapper>
                <StyledTextWithLineOffers>Galeria</StyledTextWithLineOffers>
                <StyledGallery>
                    <GalleryItems>
                        {data.galleryTypes.nodes.map((type) => (
                            <GalleryItem to={`${type.slug.current}`} key={type.id}>
                                <StyledImg image={type.image.asset.gatsbyImageData} alt={type.name}/>
                                <Content>
                                    <h3>{type.name}</h3>
                                </Content>
                            </GalleryItem>
                        ))}
                    </GalleryItems>
                </StyledGallery>
            </StyledWrapper>
        </>
    );
}

export const query = graphql`
  query GalleryTypeQuery {
    galleryTypes: allSanityGalleryType(sort: { fields: name }) {
      nodes {
        id
        name
        slug {
          current
        }
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
